import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  OrderStatusSubTitleSideNavBar,
  OrderStatusTitleSideNavBar,
} from "../components/Vendor/Utils/VendorStyledTags";
import OrdersWebViewIcon from "../assets/VendorOrders/OrdersWebViewIcon.svg";
import EventNoteIcon from "../assets/VendorOrders/StoreWebViewIcon.svg";
import POS from "../assets/VendorOrders/PosWebView.svg";
import Services from "../assets/VendorOrders/ServicesWebView.svg";
import Offers from "../assets/VendorOrders/OffersWebView.svg";
import { useNavigate } from "react-router-dom";
import { VendorProfileChangeDialogBox } from "../components/Vendor/Profile/Section/VendorProfileChangeDialogBox";
import { useDispatch } from "react-redux";
import {
  clearCart,
  clearDiscount,
  clearTotalPosCharge,
  setCustomerContactNumber,
  setCustomerName,
  setPosChargeIds,
  setSelectedOffer,
} from "../features/pos.js";
import Cookies from "js-cookie";
import { px } from "framer-motion";
import { Padding } from "@mui/icons-material";

export default function SideNavBarWebView({ isDrawerOpen, onClose, index }) {
  const dispatch = useDispatch();
  const isIOS = () => {
    if (typeof navigator !== "undefined") {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }
    return false;
  };
  function getReturnType(index) {
    switch (index) {
      case 0:
      case 4:
      case 6:
      case 100:
      case 99:
        return "double";
      case 1:
      case 2:
      case 3:
      case 5:
      case 7:
        return "single";
      default:
        return "single";
    }
  }
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const [isLogoutBoxOpen, setIsLogoutBoxOpen] = useState(false);

  const mediaQueryForDouble = useMediaQuery(
    "(min-width:700px) and (max-width:1349px)"
  );
  const mediaQueryForSingle = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );

  const isMediumScreen =
    getReturnType(index) === "double"
      ? mediaQueryForDouble
      : mediaQueryForSingle;

  const user = JSON.parse(localStorage.getItem("user"));
  const vendorName = user ? user.vendorName : null;

  const [expandedAccordionsWebView, setExpandedAccordionsWebView] = useState({
    accordionWebView1: true,
    accordionWebView2: true,
  });

  const handleAccordionWebViewToggle = (accordionWebViewKey) => {
    setExpandedAccordionsWebView((prevExpandedAccordionsWebView) => ({
      ...prevExpandedAccordionsWebView,
      [accordionWebViewKey]:
        !prevExpandedAccordionsWebView[accordionWebViewKey],
    }));
  };
  const vendorCategory = JSON.parse(localStorage.getItem("user"))[
    "vendorCategory"
  ];
  const handleClickwebviewButton = (buttonIndex) => {
    switch (buttonIndex) {
      case 0:
        navigate("/vendor/allOrders");
        break;
      case 1:
        navigate("/vendor/vendorSummary");
        break;
      case 2:
        navigate("/vendor/vendorAllItems");
        break;
      case 3:
        navigate("/vendor/ServiceOpeningHours");
        break;
      case 4:
        navigate("/vendor/vendorDeliveryConfigurations");
        break;
      case 5:
        navigate("/vendor/vendorManageDispatchMode");
        break;
      case 6:
        navigate("/vendor/vendorManagePaymentMethod");
        break;
      case 7:
        navigate("/vendor/vendorNotifyDelayDelivery");
        break;
      case 8:
        navigate("/vendor/vendorPOSAdditionalCharges");
        break;
      case 9:
        navigate("/vendor/POSHome");
        break;
      case 10:
        navigate("/vendor/vendorAllOffers");
        break;
      case 11:
        navigate("/vendor/vendorIntegrations");
        break;
      default:
        console.log("Invalid button index");
    }
  };

  const Wrapper = isMediumScreen ? SwipeableDrawer : Grid;
  const wrapperProps = isMediumScreen
    ? {
        anchor: "left",
        open: isDrawerOpen,
        onClose: onClose,
        disableBackdropTransition: !isIOS,
        disableDiscovery: isIOS,
        sx: { zIndex: 1200 },
      }
    : {};

  const handleClickLogout = () => {
    setIsLogoutBoxOpen(!isLogoutBoxOpen);
  };

  const handleClickConfirmLogout = () => {
    Cookies.remove("FEASTO_Token", { path: "/" });
    sessionStorage.clear();
    localStorage.clear();
    dispatch(clearCart());
    dispatch(setPosChargeIds([]));
    dispatch(setSelectedOffer(null));
    dispatch(setCustomerName(null));
    dispatch(setCustomerContactNumber(null));
    dispatch(clearDiscount());
    dispatch(clearTotalPosCharge());
    setIsLogoutBoxOpen(!isLogoutBoxOpen);
    navigate("/");
  };

  const logoutParams = {
    isDialogOpen: isLogoutBoxOpen,
    dialogHeading: "Logout",
    dialogContent:
      "Are you sure you want to Logout this account ? By clicking logout, you may lose your order updates notifications.",

    buttonContent1: "Cancel",

    handleClickButton1: handleClickLogout,
    buttonContent2: "Logout",

    handleClickButton2: handleClickConfirmLogout,
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        left: 0,
        top: isDrawerOpen ? 66 : 66,
        overflowY: "auto",
        height: "100%",
        borderRight: "1px solid var(--gray-200, #E5E7EB)",
        background: "#FFF",
      }}
    >
      <Wrapper {...wrapperProps}>
        {" "}
        <Container
          sx={{
            width: "354px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0px",
            padding: "16px 24px 0px 24px",
            flexShrink: 0,
            overflowY: "auto",
            marginTop: isDrawerOpen && isMediumScreen ? "74px" : "0px",
            marginBottom: "150px",
          }}
        >
          <Accordion
            expanded={expandedAccordionsWebView.accordionWebView1}
            sx={{
              border: "none",
              boxShadow: "none",
              margin: "0px",
              "&::before": {
                background: "#FFF",
              },
              cursor: "pointer",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px",
                width: "306px",
                padding: "10px 16px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={OrdersWebViewIcon} alt="Orders Web View" />
                <OrderStatusTitleSideNavBar>Orders</OrderStatusTitleSideNavBar>
              </Grid>
              <Grid>
                <ExpandMoreIcon
                  onClick={() =>
                    handleAccordionWebViewToggle("accordionWebView1")
                  }
                  sx={{
                    fontSize: "30px",
                    paddingTop: expandedAccordionsWebView.accordionWebView1
                      ? "0px"
                      : "0px ",
                    transform: expandedAccordionsWebView.accordionWebView1
                      ? ""
                      : "rotate(-90deg)",
                  }}
                />
              </Grid>
            </Grid>
            <AccordionDetails sx={{ padding: "0px" }}>
              {expandedAccordionsWebView.accordionWebView1 === true && (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    margin: "0px 24px",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    onClick={() => handleClickwebviewButton(0)}
                    sx={{
                      display: "flex",
                      background:
                        index === 0 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar>
                      All orders
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    onClick={() => handleClickwebviewButton(1)}
                    sx={{
                      display: "flex",
                      background:
                        index === 1 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar>
                      Order summary
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>

          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              width: "306px",
              padding: "10px 16px",
              justifyContent: "space-between",
              cursor: "pointer",
              background: index === 2 ? "var(--gray-100, #F3F4F6)" : "#FFF",
              borderRadius: "6px",
            }}
          >
            <Grid
              onClick={() => handleClickwebviewButton(2)}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <img src={EventNoteIcon} alt="Orders Web View" />
              <OrderStatusTitleSideNavBar>Store</OrderStatusTitleSideNavBar>
            </Grid>
            <Grid></Grid>
          </Grid>

          <Accordion
            expanded={expandedAccordionsWebView.accordionWebView2}
            sx={{
              border: "none",
              boxShadow: "none",
              margin: "0px",
              "&::before": {
                background: "#FFF",
              },
              cursor: "pointer",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px",
                width: "306px",
                padding: "10px 16px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={Services} alt="Services Web View" />
                <OrderStatusTitleSideNavBar>
                  Services
                </OrderStatusTitleSideNavBar>
              </Grid>
              <Grid>
                <ExpandMoreIcon
                  onClick={() =>
                    handleAccordionWebViewToggle("accordionWebView2")
                  }
                  sx={{
                    fontSize: "30px",
                    paddingTop: expandedAccordionsWebView.accordionWebView2
                      ? "0px"
                      : "0px ",
                    transform: expandedAccordionsWebView.accordionWebView2
                      ? ""
                      : "rotate(-90deg)",
                  }}
                />
              </Grid>
            </Grid>
            <AccordionDetails sx={{ padding: "0px" }}>
              {expandedAccordionsWebView.accordionWebView2 === true && (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    margin: "0px 24px",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    onClick={() => handleClickwebviewButton(3)}
                    sx={{
                      display: "flex",
                      background:
                        index === 3 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar>
                      Opening hours{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      background:
                        index === 4 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar
                      onClick={() => handleClickwebviewButton(4)}
                    >
                      Delivery configurations{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      background:
                        index === 5 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar
                      onClick={() => handleClickwebviewButton(5)}
                    >
                      Operating modes{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      background:
                        index === 6 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar
                      onClick={() => handleClickwebviewButton(6)}
                    >
                      Payment methods{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  {["GROCERY", "RETAIL"].includes(
                    JSON.parse(localStorage.getItem("user"))?.vendorCategory
                  ) && (
                    <Grid
                      sx={{
                        display: "flex",
                        background:
                          index === 7 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                        borderRadius: "6px",
                        marginRight: "50px",
                      }}
                    >
                      <OrderStatusSubTitleSideNavBar
                        onClick={() => handleClickwebviewButton(7)}
                      >
                        Notify delay delivery{" "}
                      </OrderStatusSubTitleSideNavBar>
                    </Grid>
                  )}
                  <Grid
                    sx={{
                      display: "flex",
                      background:
                        index === 8 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar
                      onClick={() => handleClickwebviewButton(8)}
                    >
                      POS - Additional charges{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      background:
                        index === 11 ? "var(--gray-100, #F3F4F6)" : "#FFF",
                      borderRadius: "6px",
                      marginRight: "50px",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar
                      onClick={() => handleClickwebviewButton(11)}
                    >
                      Integrations
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  {/* <Grid
                    sx={{
                      display: "flex",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar>
                      Integrations{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                    }}
                  >
                    <OrderStatusSubTitleSideNavBar>
                      Wholesale business{" "}
                    </OrderStatusSubTitleSideNavBar>
                  </Grid> */}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>

          <Grid
            onClick={() => handleClickwebviewButton(9)}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              width: "306px",
              padding: "10px 16px",
              justifyContent: "space-between",
              background:
                index === 99 || index === 9
                  ? "var(--gray-100, #F3F4F6)"
                  : "#FFF",

              cursor: "pointer",
              borderRadius: "6px",
              marginBottom: "20px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "6px",
              }}
            >
              <img src={POS} alt="Orders Web View" />
              <OrderStatusTitleSideNavBar>POS</OrderStatusTitleSideNavBar>
            </Grid>
            <Grid></Grid>
          </Grid>

          <Grid
            onClick={() => handleClickwebviewButton(10)}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              width: "306px",
              padding: "10px 16px",
              justifyContent: "space-between",
              background:
                index === 10 || index === 100
                  ? "var(--gray-100, #F3F4F6)"
                  : "#FFF",

              cursor: "pointer",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={Offers} alt="Orders Web View" />
              <OrderStatusTitleSideNavBar>Offers</OrderStatusTitleSideNavBar>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
        <Box
          style={{
            display: "flex",
            gap: "0px",
            Padding: "16px 24px 24px 24px",
            background: "#FFF",
            bottom: 0,
            left: 20,
            position: "fixed",
          }}
        >
          {" "}
          <Button
            onClick={handleClickLogout}
            style={{
              display: "flex",
              padding: "17px 24px",
              alignItems: "center",
              width: "312px",
              height: "52px",
              background: "#FFF",
              color: "#000",
              border: "1px solid var(--gray-300, #D1D5DB)",
              margin: "0px 0px",
              borderRadius: "8px",

              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                color: "var(--gray-800, #1F2937)",
                fontFamily: "Poppins",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Sign out
            </Typography>
          </Button>
        </Box>
        <VendorProfileChangeDialogBox params={logoutParams} />
      </Wrapper>{" "}
    </Grid>
  );
}
