import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomVendorContainer,
  VendorSubTypography,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { toast } from "react-toastify";
import { axiosVendorServiceInstance } from "../../../../services/api";
import VendorBottomNavbar from "../../../../layouts/VendorBottomNavbar";

export const VendorManageBankDetails = ({ bankDetail }) => {
  console.log(bankDetail);
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorManagePaymentMethod", {
      state: bankDetails,
    });
  };

  const [bankDetails, setBankDetails] = useState(
    location.state === null ? bankDetail : location.state
  );

  console.log(bankDetails);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...bankDetails];
    list[index][name] = value;
    setBankDetails(list);
  };

  const handleAddCard = () => {
    setBankDetails([
      ...bankDetails,
      { accountName: "", accountNumber: "", bankName: "", branch: "" },
    ]);
  };

  const handleDeleteCard = (index) => {
    if (bankDetails.length === 1) {
      setBankDetails([
        { accountName: "", accountNumber: "", bankName: "", branch: "" },
      ]);
    } else {
      const list = [...bankDetails];
      list.splice(index, 1);
      setBankDetails(list);
      toast.success("Bank Account Removed Successfully!");
    }
  };

  const validateBankDetails = (details) => {
    return details.every(
      (detail) =>
        detail.accountName !== "" &&
        detail.accountNumber !== "" &&
        detail.bankName !== ""
    );
  };

  const handleSave = async () => {
    try {
      if (validateBankDetails(bankDetails)) {
        await axiosVendorServiceInstance.post(
          `/vendor-service/update-bank-details?userId=${JSON.parse(
            localStorage.getItem("userId")
          )}`,
          bankDetails
        );
        navigate("/vendor/vendorUpdatingBankDetailsSuccess");
        setTimeout(() => {
          if (isBoth) {
            navigate("/vendor/vendorManagePaymentMethod");
          } else {
            navigate("/vendor/vendorService");
          }
        }, 1500);
      } else {
        toast.error("Check The Bank Details You Entered");
      }
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <main>
        <CustomVendorContainer style={{ background: "#FFF" }}>
          <Container
            maxWidth="sm"
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container
              maxWidth="sm"
              style={{ flexGrow: 1, padding: "0px 12px" }}
            >
              <ViewStatusBlock
                style={{
                  justifyContent: isBoth ? "space-between" : "center",
                  padding: isBoth ? "0px 0px" : "0px",
                  position: "relative",
                  marginLeft: isBoth ? "0px" : "13px",
                }}
              >
                {!isBoth && (
                  <ArrowBackIosIcon
                    onClick={handleClickBackIcon}
                    sx={{ position: "absolute", top: isBoth ? 10 : 0, left: 0 }}
                  />
                )}

                <Typography
                  style={{
                    fontWeight: isBoth ? 600 : 500,
                    color: "#1C1C1C",
                    fontFamily: "Poppins",
                    fontSize: isBoth ? "20px" : "18px",
                    margin: "0px",
                    padding: "0px",
                    lineHeight: "130%",
                    textTransform: "capitalize",
                  }}
                >
                  Bank Accounts
                </Typography>
              </ViewStatusBlock>{" "}
              <Typography
                style={{
                  fontWeight: 400,
                  color: "var(--gray-600, #4B5563)",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "130%",
                  textTransform: "capitalize",
                  marginTop: "30px",
                  textAlign: "left",
                }}
              >
                Manage the bank accounts where you want to receive payments from
                customers.{" "}
              </Typography>
              <Box sx={{ marginTop: "50px" }}>
                {bankDetails.map((bank, index) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: "8px",
                      background: "var(--white, #FFF)",
                      boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.25)",
                      padding: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <input
                      type="text"
                      name="accountName"
                      value={bank.accountName}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Account Name"
                      style={{
                        width: "85%",
                        height: "40px",
                        borderRadius: "8px",
                        margin: "8px 0",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                        border: "1px solid var(--gray-300, #D1D5DB)",
                      }}
                    />
                    <input
                      type="text"
                      name="accountNumber"
                      value={bank.accountNumber}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Account Number"
                      style={{
                        width: "85%",
                        height: "40px",
                        borderRadius: "8px",
                        margin: "8px 0",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                        border: "1px solid var(--gray-300, #D1D5DB)",
                      }}
                    />
                    <input
                      type="text"
                      name="bankName"
                      value={bank.bankName}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Name of the Bank"
                      style={{
                        width: "85%",
                        height: "40px",
                        borderRadius: "8px",
                        margin: "8px 0",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                        border: "1px solid var(--gray-300, #D1D5DB)",
                      }}
                    />
                    <input
                      type="text"
                      name="branch"
                      value={bank.branch}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Branch"
                      style={{
                        width: "85%",
                        height: "40px",
                        borderRadius: "8px",
                        margin: "8px 0 16px 0",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                        border: "1px solid var(--gray-300, #D1D5DB)",
                      }}
                    />
                    <div
                      onClick={() => handleDeleteCard(index)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        paddingRight: "0px",
                        alignItems: "center",
                        border: "1px dashed var(--red-500, #EF4444)",
                        borderRadius: "6px",
                        margin: "8px 16px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton>
                        <DeleteOutlineRoundedIcon
                          style={{
                            color: "var(--red-500, #EF4444)",
                          }}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          color: "var(--red-500, #EF4444)",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "16px",
                        }}
                      >
                        Remove account
                      </Typography>
                    </div>
                  </div>
                ))}
                <Grid
                  onClick={handleAddCard}
                  sx={{
                    display: "flex",
                    borderRadius: "16px",
                    padding: "16px",
                    border: "1px dashed var(--gray-500, #6B7280)",
                    background: "var(--white, #FFF)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      display: "flex",
                      padding: "0px 0px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: "0",
                      borderRadius: "8px",
                    }}
                  >
                    <AddRoundedIcon
                      style={{
                        color: "#6B7280",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      color: "var(--gray-500, #6B7280)",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "16px",
                    }}
                  >
                    Add Account
                  </Typography>
                </Grid>

                {/* <button onClick={handleAddCard}>Add</button>
              <button onClick={handleSave}>Save</button> */}
                <Container
                  style={{
                    position: isBoth ? "" : "relative",
                    bottom: "30px",
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "0px",
                    left: 0,
                    right: 0,
                    gap: "16px",
                  }}
                >
                  <Button
                    style={{
                      borderRadius: "8px",
                      background: "var(--black, #000)",
                      alignContent: "flex-start",
                      width: "50%",
                    }}
                    onClick={handleClickBackIcon}
                    //   disabled={isSaveDisabled}
                  >
                    <Typography
                      style={{
                        color: "var(--white, #FFF)",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18px" /* 112.5% */,
                        textTransform: "capitalize",
                        padding: "10px 31px",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Button>

                  <Button
                    style={{
                      borderRadius: "8px",
                      background: "#059669",
                      alignContent: "flex-start",
                      width: "50%",
                    }}
                    onClick={handleSave}
                    //   disabled={isSaveDisabled}
                  >
                    <Typography
                      style={{
                        color: "var(--white, #FFF)",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18px" /* 112.5% */,
                        textTransform: "capitalize",
                        padding: "10px 31px",
                      }}
                    >
                      Save
                    </Typography>
                  </Button>
                </Container>
              </Box>
            </Container>
            <div style={{ marginTop: "10px" }}>
              {/* {!isBoth && <VendorBottomNavbar clickedIndex={2} />} */}
            </div>
          </Container>
        </CustomVendorContainer>
      </main>
    </>
  );
};
