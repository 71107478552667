import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
const customerServiceUrl = process.env.REACT_APP_CUSTOMER_SERVICE_BASE_URL;
const vendorServiceUrl = process.env.REACT_APP_VENDOR_SERVICE_BASE_URL;
const adminServiceUrl = process.env.REACT_APP_ADMIN_SERVICE_BASE_URL;
const orderServiceUrl = process.env.REACT_APP_ORDER_SERVICE_BASE_URL;
const upviewUrl = process.env.REACT_APP_UPVIEW_BASE_URL;

axios.defaults.withCredentials = true;
export const axiosAuthServiceInstance = axios.create({
  baseURL: authServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosCustomerServiceInstance = axios.create({
  baseURL: customerServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosVendorServiceInstance = axios.create({
  baseURL: vendorServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAdminServiceInstance = axios.create({
  baseURL: adminServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosOrderServiceInstance = axios.create({
  baseURL: orderServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosUpviewInstance = axios.create({
  baseURL: upviewUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

axiosCustomerServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginCustomer/email";
    }
    return Promise.reject(error);
  }
);

axiosVendorServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosOrderServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state?.locationState,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosAuthServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = ["/auth/loginAdmin", "auth/loginVendor/email"].includes(window.location.pathname) ? window.location.pathname : "/auth/loginCustomer/email";
    }
    return Promise.reject(error);
  }
);
