import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputTimeRangeField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddRoundedIcon from "@mui/icons-material/AddCircle";
import {
  DIALOG_HEADING_STYLE,
  NOTIFY_DELAYED_DELIVERY_AREA_CUSTOM_BOX,
} from "../../Utils/VendorStyledObjects";

import {
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  CssBaseline,
  Container,
  Typography,
  Grid,
  SwipeableDrawer,
  Box,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/RemoveCircle";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  CustomVendorContainer,
  VendorConfirmButton,
  VendorSubTypography,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";
import VendorBottomNavbar from "../../../../layouts/VendorBottomNavbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "rsuite";
import DeliveryAreaIsNotUniqueMsg from "../Section/DeliveryAreaIsNotUniqueMsg";
import { useVendor } from "../../../../contexts/VendorContext";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";
import { VendorWebViewInfoDialogBox } from "../../../../Styles";
import { FullscreenExit } from "@mui/icons-material";

const convertFormattedDataToAreas = (formattedData) => {
  if (!formattedData || typeof formattedData !== "object") {
    return [];
  }

  return Object.keys(formattedData).map((area) => {
    const areaData = formattedData[area];
    const schedules = Object.keys(areaData.schedule).reduce((acc, day) => {
      areaData.schedule[day].forEach((timeRange) => {
        const [startTime, endTime] = timeRange.split("-");
        acc.push({
          day,
          times: [
            dayjs(`2022-04-17T${startTime}`),
            dayjs(`2022-04-17T${endTime}`),
          ],
        });
      });
      return acc;
    }, []);
    return {
      area,
      price: areaData.price,
      showSchedule: schedules.length > 0,
      schedule:
        schedules.length > 0
          ? schedules
          : [{ day: "", times: [dayjs(), dayjs()] }],
    };
  });
};

const DeliveryForm = ({ areasAndDeliveryFee, themeColor }) => {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const location = useLocation();
  const navigate = useNavigate();

  const [areas, setAreas] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentAreaIndex, setCurrentAreaIndex] = useState(null);
  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(null);
  const [currentDrawerDay, setCurrentDrawerDay] = useState(null);
  const [isAreaNotUniqueDialogOpen, setIsAreaNotUniqueDialogOpen] =
    useState(false);
  const timeFormat = JSON.parse(localStorage.getItem("user"))["timeFormat"];

  const confimationStatusDataForAreaNotUnique = {
    isDialogOpen: true,
    dialogHeading: "Area Already Exists",
    dialogContent:
      "The area you are trying to add already exists in the system. Please review the list of existing areas or enter a new area name.",
  };

  const handleClickBackIcon = () => {
    if (isBoth) {
      navigate("/vendor/DeliveryAreaFee");
    } else {
      navigate("/vendor/vendorService");
    }
  };

  useEffect(() => {
    const loadData = () => {
      const formattedData = location.state
        ? location.state
        : areasAndDeliveryFee || {};
      const initialAreas = convertFormattedDataToAreas(formattedData);
      setAreas(initialAreas);
    };

    loadData();
  }, []);

  const handleAddArea = () => {
    setAreas([
      ...areas,
      {
        area: "",
        price: "",
        showSchedule: false,
        schedule: [{ day: "", times: [dayjs(), dayjs()] }],
      },
    ]);
  };

  const handleRemoveArea = (index) => {
    const newAreas = areas.filter((_, i) => i !== index);
    setAreas(newAreas);
  };

  const handlePriceChange = (index, field, value) => {
    const newAreas = [...areas];
    const formattedValue = value.match(/^\d*\.?\d{0,2}/)?.[0] || "";
    newAreas[index][field] = formattedValue;
    setAreas(newAreas);
  };

  const handleAreaChange = (index, field, value) => {
    const newAreas = [...areas];
    newAreas[index][field] = value;
    setAreas(newAreas);
  };

  const handleAddSchedule = (index) => {
    const newAreas = [...areas];
    newAreas[index].schedule.push({ day: "", times: [dayjs(), dayjs()] });
    setAreas(newAreas);
  };

  const handleRemoveSchedule = (areaIndex, scheduleIndex) => {
    const newAreas = [...areas];
    newAreas[areaIndex].schedule = newAreas[areaIndex].schedule.filter(
      (_, i) => i !== scheduleIndex
    );
    setAreas(newAreas);
  };

  const handleScheduleChange = (areaIndex, scheduleIndex, field, value) => {
    const newAreas = [...areas];
    newAreas[areaIndex].schedule[scheduleIndex][field] = value;
    setAreas(newAreas);
  };

  const handleTimeRangeChange = (areaIndex, scheduleIndex, value) => {
    const newAreas = [...areas];
    newAreas[areaIndex].schedule[scheduleIndex].times = value;
    setAreas(newAreas);
  };

  const handleCheckboxChange = (index) => {
    const newAreas = [...areas];
    newAreas[index].showSchedule = !newAreas[index].showSchedule;
    if (!newAreas[index].showSchedule) {
      newAreas[index].schedule = [{ day: "", times: [dayjs(), dayjs()] }];
    }
    setAreas(newAreas);
  };

  const openDrawer = (areaIndex, scheduleIndex, drawerDay) => {
    setCurrentAreaIndex(areaIndex);
    setCurrentScheduleIndex(scheduleIndex);
    setCurrentDrawerDay(drawerDay);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const selectDay = (day) => {
    if (currentAreaIndex !== null && currentScheduleIndex !== null) {
      setCurrentDrawerDay(day);
      handleScheduleChange(currentAreaIndex, currentScheduleIndex, "day", day);
    }
    closeDrawer();
  };

  const saveData = async () => {
    const uniqueAreas = [];
    for (const area of areas) {
      const trimmedArea = area.area.trim();
      if (trimmedArea !== "" && uniqueAreas.includes(trimmedArea)) {
        setIsAreaNotUniqueDialogOpen(true);
        return;
      }
      uniqueAreas.push(trimmedArea);
    }

    const formattedData = areas.reduce((acc, area) => {
      if (area.area) {
        acc[area.area.trim()] = {
          price: Number(area.price),
          schedule: area.showSchedule
            ? area.schedule.reduce((scheduleAcc, s) => {
                const timeRange = `${s.times[0].format(
                  "HH:mm"
                )}-${s.times[1].format("HH:mm")}`;
                if (!scheduleAcc[s.day]) {
                  scheduleAcc[s.day] = [];
                }
                scheduleAcc[s.day].push(timeRange);
                return scheduleAcc;
              }, {})
            : {},
        };
      }
      return acc;
    }, {});

    try {
      await axiosVendorServiceInstance.post(
        `/vendor-service/change-area-delivery-fee?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}`,
        formattedData
      );

      navigate("/vendor/vendorUpdatingAreaFeeSuccess");
      setTimeout(() => {
        if (isBoth) {
          navigate("/vendor/vendorDeliveryConfigurations");
        } else {
          navigate("/vendor/vendorService");
        }
      }, 1500);
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  const Wrapper = !isBoth ? SwipeableDrawer : VendorWebViewInfoDialogBox;

  const wrapperProps = !isBoth
    ? {
        PaperProps: {
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        },
        anchor: "bottom",
        open: drawerOpen,
        onClose: () => setDrawerOpen(false),
        sx: { width: "100%", maxWidth: "100%", background: "transparent" },
        "aria-setsize": FullscreenExit,
      }
    : {
        open: drawerOpen,
      };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            minHeight: "100vh",
            background: "#FFF",
          }}
        >
          <CustomVendorContainer style={{ background: "#FFF" }}>
            <DeliveryAreaIsNotUniqueMsg
              confimationStatusData={confimationStatusDataForAreaNotUnique}
              statusChangeState={[
                isAreaNotUniqueDialogOpen,
                setIsAreaNotUniqueDialogOpen,
              ]}
            />
            <Container
              maxWidth="sm"
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                padding: "0px 12px",
              }}
            >
              <Container
                maxWidth="sm"
                style={{ flexGrow: 1, overflowY: "auto", pb: "70px" }}
              >
                <ViewStatusBlock
                  style={{
                    justifyContent: isBoth ? "space-between" : "center",
                    paddingBottom: isBoth ? "16px" : "0px",
                    position: "relative",
                    marginTop: isBoth ? "0px" : "10px",
                  }}
                >
                  {!isBoth && (
                    <ArrowBackIosIcon
                      onClick={handleClickBackIcon}
                      sx={{
                        position: "absolute",
                        top: isBoth ? 5 : 0,
                        left: -10,
                      }}
                    />
                  )}

                  <Typography
                    style={{
                      fontWeight: isBoth ? 600 : 500,
                      color: "#1C1C1C",
                      fontFamily: "Poppins",
                      fontSize: isBoth ? "28px" : "18px",
                      margin: isBoth ? " 0px" : "0px",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                    }}
                  >
                    Delivery Area
                  </Typography>
                </ViewStatusBlock>

                <div style={{ marginTop: isBoth ? "0px" : "20px" }}>
                  <Typography
                    style={{
                      color: "#4B5563",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textAlign: "left",
                      marginBottom: isBoth ? "40px" : "40px",
                    }}
                  >
                    Configure your delivery area and delivery charge to
                    streamline your delivery process.
                  </Typography>
                  {areas.map((area, areaIndex) => (
                    <div
                      key={areaIndex}
                      style={{
                        border: "1px solid #E5E7EB",
                        borderRadius: 14,
                        margin: "10px",
                        padding: "10px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div>
                          <TextField
                            label="Delivery Area"
                            value={area.area}
                            onChange={(e) =>
                              handleAreaChange(
                                areaIndex,
                                "area",
                                e.target.value
                              )
                            }
                            InputProps={{
                              sx: {
                                color: "#111827",
                              },
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            label="Price"
                            type="number"
                            value={area.price}
                            inputProps={{ min: 0 }}
                            onChange={(e) =>
                              handlePriceChange(
                                areaIndex,
                                "price",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={area.showSchedule}
                            onChange={() => handleCheckboxChange(areaIndex)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#374151" }}>
                            Add specific delivery days & delivery time.
                          </Typography>
                        }
                      />
                      {area.showSchedule && (
                        <div>
                          {area.schedule.map((schedule, scheduleIndex) => (
                            <div
                              key={scheduleIndex}
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Container
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "48px",
                                    width: "90px",
                                    border: "1px solid #E5E7EB",
                                    borderRadius: "8px",
                                    marginRight: "5px",
                                  }}
                                  variant="outlined"
                                  onClick={() =>
                                    openDrawer(
                                      areaIndex,
                                      scheduleIndex,
                                      schedule.day
                                    )
                                  }
                                >
                                  {schedule.day.substring(0, 3) || "Select Day"}
                                  <ExpandMoreIcon />
                                </Container>
                              </div>
                              <div>
                                <SingleInputTimeRangeField
                                  label="Time Range"
                                  value={schedule.times}
                                  ampm={
                                    timeFormat === "_24HOURS" ? false : true
                                  }
                                  onChange={(newValue) =>
                                    handleTimeRangeChange(
                                      areaIndex,
                                      scheduleIndex,
                                      newValue
                                    )
                                  }
                                  InputProps={{
                                    sx: {
                                      fontSize: "14px", // Change text color to red
                                    },
                                  }}
                                />
                              </div>
                              {scheduleIndex !== area.schedule.length - 1 ? (
                                <IconButton
                                  sx={{ padding: "0px" }}
                                  onClick={() =>
                                    handleRemoveSchedule(
                                      areaIndex,
                                      scheduleIndex
                                    )
                                  }
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "var(--gray-800, #1F2937)",
                                      fontSize: "48px",
                                      padding: "0px",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  sx={{ padding: "0px" }}
                                  onClick={() => handleAddSchedule(areaIndex)}
                                >
                                  <AddRoundedIcon
                                    sx={{ color: "#059669", fontSize: "48px" }}
                                  />
                                </IconButton>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      <Container
                        fullWidth
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          border: "2px dashed #EF4444",
                          backgroundColor: "#FFFFFF",
                          color: "#EF4444",
                          cursor: "pointer",
                          borderRadius: "8px",
                          padding: "4px",
                          marginTop: "16px",
                        }}
                        onClick={() => handleRemoveArea(areaIndex)}
                      >
                        <DeleteForeverOutlinedIcon />
                        Remove Area
                      </Container>
                    </div>
                  ))}
                  <Container
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      border: "2px dashed #6B7280",
                      backgroundColor: "#FFFFFF",
                      color: "#6B7280",
                      width: "92%",
                      borderRadius: "8px",
                      padding: "4px",
                    }}
                    onClick={handleAddArea}
                  >
                    <AddOutlinedIcon />
                    Add More
                  </Container>
                </div>
                <Grid
                  sx={{
                    position: isBoth ? "relative" : "fixed",
                    marginTop: isBoth ? "100vh" : "0px",
                    bottom: 20, // 50px from bottom
                    left: 0,
                    right: 0,
                    padding: "10px",
                    background: "#fff",
                    zIndex: 1000,
                  }}
                >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                          

                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "400",
                            textTransform: "capitalize",
                            padding: "16px 13px",
                            background: "#000",
                            borderRadius: "8px",
                            "&:hover": { backgroundColor: "#333" },
                          }}
                          onClick={handleClickBackIcon}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                 
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "400",
                            padding: "16px 13px",
                            backgroundColor: "#059669",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                            "&:hover": { backgroundColor: "#046c4e" },
                          }}
                          onClick={saveData}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                </Grid>
              </Container>
              {/* {!isBoth && <VendorBottomNavbar clickedIndex={2} />} */}
            </Container>
          </CustomVendorContainer>
        </Box>

        <Wrapper {...wrapperProps}>
          <Container
            maxWidth="sm"
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: "26px",
              borderRadius: "48px",
              justifyContent: "space-between",
              background: "#FFF",
            }}
          >
            <DialogContentText
              style={{ ...DIALOG_HEADING_STYLE, margin: "0px" }}
            >
              Select specific day
            </DialogContentText>
            <Divider />
            <Container
              style={{
                ...NOTIFY_DELAYED_DELIVERY_AREA_CUSTOM_BOX,
                minHeight: "60px",
              }}
              // onClick={handleAreaSelectDrawer}
            >
              {[
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
                "SUNDAY",
              ].map((day) => (
                <Box
                  key={day}
                  sx={{
                    padding: "0 12px",
                    border: "1px solid #4B5563",
                    borderRadius: "16px",
                    backgroundColor:
                      currentDrawerDay === day ? themeColor : "#f0f0f0",
                    flex: "0 0 auto",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    height: "28px",
                    width: "auto",
                    color: currentDrawerDay === day ? "#FFFFFF" : "#4B5563",
                  }}
                  onClick={() => selectDay(day)}
                >
                  {day}
                </Box>
              ))}
            </Container>
          </Container>
        </Wrapper>
      </main>
    </LocalizationProvider>
  );
};

export default DeliveryForm;
