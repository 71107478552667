import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import React from "react";
import {
  BackButtonIcon,
  CustomVendorContainer,
  VendorServicePortalFrame,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import upviewlogo from "../../../../assets/VendorProfile/upviewlogo.svg";
import { useNavigate } from "react-router-dom";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";

export const VendorIntegrations = () => {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;

  const navigate = useNavigate();

  const integrations =
    JSON.parse(localStorage.getItem("user"))?.integrations ?? [];
  const isActiveUpviewIntegrated =
    integrations.filter(
      (integ) => integ.platform === "UPVIEW" && integ.isActive
    ).length > 0;

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const handleClickVenderUpView = () => {
    navigate("/vendor/vendorUpView");
  };

  return (
    <>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            minHeight: "100vh",
            background: "var(--gray-50, #F9FAFB)",
          }}
        >
          {isBoth && <TopNavBarWebView index={11} />}
          <CustomVendorContainer>
            <Container
              maxWidth="sm"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: isBoth ? "0px 0px 12px" : "0px",
                margin: isLargeScreen
                  ? `40px calc((100% - 1060px) / 2) 40px calc(((100% - 1060px) / 2) + 400px)`
                  : "0px auto",
                border: isBoth ? "1px solid var(--gray-200, #E5E7EB)" : "none",
                background: "#FFF",
                borderRadius: "20px",
                minHeight: "calc(100vh - 80px)",
                position: "relative",
              }}
            >
              <Container maxWidth="sm">
                <ViewStatusBlock
                  style={{
                    justifyContent: isBoth ? "space-between" : "center",
                    borderBottom: isBoth
                      ? "1px solid var(--gray-200, #E5E7EB)"
                      : "none",
                    paddingBottom: isBoth ? "16px" : "0px",
                    position: "relative",
                  }}
                >
                  {!isBoth && (
                    <BackButtonIcon
                      onClick={handleClickBackIcon}
                      sx={{
                        position: "absolute",
                        top: isBoth ? 5 : 0,
                        left: 0,
                      }}
                    />
                  )}

                  <Typography
                    style={{
                      fontWeight: isBoth ? 600 : 500,
                      color: "#1C1C1C",
                      fontFamily: "Poppins",
                      fontSize: isBoth ? "28px" : "18px",
                      margin: isBoth ? " 0px 0px" : "0px",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                    }}
                  >
                    Integrations
                  </Typography>
                </ViewStatusBlock>

                <VendorServicePortalFrame style={{ marginTop: "10px" }}>
                  <Container
                    onClick={handleClickVenderUpView}
                    style={{
                      border: "1px solid #E5E7EB",
                      borderRadius: "16px",
                      padding: "12px 10px",
                      justifycontent: "space-between",
                      alignitems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "12px",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <Grid>
                          {" "}
                          <img
                            src={upviewlogo}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        </Grid>
                        <Typography
                          style={{
                            color: "#111827",
                            fontfamily: "Poppins",
                            fontsize: "16px",
                            fontstyle: "normal",
                            fontweight: "400",
                            lineheight: "normal",
                          }}
                        >
                          Upview POS
                        </Typography>
                      </Grid>
                      {isActiveUpviewIntegrated && (
                        <Grid>
                          <Container
                            style={{
                              display: "flex",
                              padding: "1px 12px",
                              justifycontent: "center",
                              alignitems: "center",
                              borderRadius: "16px",
                              backgroundColor: "#D1FAE5",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#065F46",
                                textalign: "center",
                                fontfamily: "Poppins",
                                fontsize: "12px",
                                fontstyle: "normal",
                                fontweight: "500",
                                lineheight: "20px",
                              }}
                            >
                              CONNECTED
                            </Typography>
                          </Container>
                        </Grid>
                      )}
                    </Grid>
                  </Container>
                </VendorServicePortalFrame>
              </Container>
              {/* <VendorBottomNavbar clickedIndex={2} /> */}
            </Container>
          </CustomVendorContainer>
        </Box>
      </main>
    </>
  );
};
